@font-face {
  font-family: 'SharpSansDisplayNo1-Bold';
  font-style: normal;
  font-weight: normal;
  src: url('../assets/fonts/SharpSansDisplayNo1-Bold.otf');
}

// @font-face {
//   font-family: 'sharp-sans-display-light';
//   font-style: normal;
//   font-weight: normal;
//   src: url('../assets/fonts/SharpSansDisplayNo1-Light.otf');
// }

@font-face {
  font-family: 'SharpSansDisplayNo1-Semibold;';
  font-style: normal;
  font-weight: normal;
  src: url('../assets/fonts/SharpSansDisplayNo1-Semibold.otf');
}

@font-face {
  font-family: 'NeueHaasUnicaPro-Regular;';
  font-style: normal;
  font-weight: normal;
  src: url('../assets/fonts/neue-haas-unica-pro-regular.otf');
}

@font-face {
  font-family: 'NeueHaasUnicaPro-Heavy;';
  font-style: normal;
  font-weight: normal;
  src: url('../assets/fonts/NeueHaasUnicaPro-Heavy.otf');
}

@font-face {
  font-family: 'NeueHaasUnicaPro-Light;';
  font-style: normal;
  font-weight: normal;
  src: url('../assets/fonts/NeueHaasUnicaPro-Light.otf');
}