@mixin border-bottom($color) {
  border-bottom: 2px solid $color;
  padding-top: 3px;
}

@mixin info-padding {
  padding: 15px 0 15px 0;
}

@mixin copy {
  color: $color-grey;
  @include bodyCopyMed;
  @include info-padding;
}

@mixin link-hover {
  &:hover {
    color: $lightBlue;
    transition: color 0.3s ease;
  }
}