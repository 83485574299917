$ns: '.split-columns';

#{$ns} {
  padding: 170px 0 80px;
  position: relative;

  .grid-container {
    @include small-up {
      display: flex;
    }
  }

  &__col {
    @include small-up {
      width: 48%;

      &:last-child {
        margin-right: 0%;
      }

      &:first-child {
        margin-right: 4%;
      }
    }
  }

  &__list {
    padding: 0;
    list-style: none;

    &--item {
      margin-bottom: 60px;
      max-width: 385px;
    }
  }

  &__triangle {
    display: none;

    @include medium-up {
      display: block;
      position: absolute;
      top: -300px;
      left: 0;
    }
  }

  &__headline {
    @include headline3;
    margin-bottom: 60px;
  }

  &__title {
    @include subheadline1;
    margin-bottom: 30px;
  }

  &__copy {
    @include bodyCopyMed;
  }
}
