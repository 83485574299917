$ns: '.slider-fancy';

#{$ns} {
  //max-width: 880px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  //max-width: 90%;

  p {
    margin-top: 35px;
  }

  .slick-slider {
    max-width: 80%;
    padding-left: 5%;

    @include medium-up {
      max-width: 1180px;
      margin: 0 auto;
      padding-left: 0;
    }

    &:before {
      content: '';
      position: absolute;
      height: 100%;
      width: 100vw;
      background: #f3f3f3;
      right: 1140px;
      z-index: 1;
    }

    &:after {
      content: '';
      position: absolute;
      height: 100%;
      width: 100vw;
      background: rgba(243, 243, 243, 0.5);
      left: 1140px;
      z-index: 1;
      top: 0;
    }
  }

  .slick-list {
    overflow: visible;
  }

  .slick-dots {
    position: absolute;
    bottom: -30px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: left;

    @include medium-up {
      text-align: center;
    }
    li {
      height: 3px;
      button {
        height: 3px;
        padding: 0;
        transition: $transition;
        &:hover {
          &:before {
            background: $lightBlue;
          }
        }
        &:before {
          content: '';
          transition: $transition;
          background: $black;
          opacity: 1;
          width: 22px;
          height: 3px;
        }
      }

      &.slick-active {
        button:before {
          background: $lightBlue;
          opacity: 1;
        }
      }
    }
  }

  .slick-arrow {
    display: none !important;

    @media screen and (min-width: 1260px) {
      display: block !important;
    }
  }

  .slick-next,
  .slick-prev {
    width: 50px;

    &:before {
      opacity: 1;
      top: -6px;
      position: relative;
    }
  }

  .slick-next {
    z-index: 25;
    //right: -200px;
    &:before {
      content: url(https://prismic-io.s3.amazonaws.com/alliance-consumer-growth%2Fa9b1707a-8455-4d43-ba5f-d91e760a785c_right-arrow.svg);
    }
  }

  .slick-prev {
    z-index: 25;
    //left: -200px;
    &:before {
      content: url(https://prismic-io.s3.amazonaws.com/alliance-consumer-growth%2F77ba1c2d-ee04-46bf-8d4f-f9d3bed31d37_left-arrow.svg);
    }
  }

  &__slide {
    //padding: 0 10px;
    img {
      width: 100%;
      width: 100%;
      max-width: 90%;

      @include medium-up {
        max-width: 980px;
        margin: 0 auto;
      }
    }

    &:focus {
      outline: none !important;
    }

    &--two {
      display: inline-flex;
      padding-left: 20px;
      img {
        max-height: 348px;
        max-width: 280px;
      }
    }
  }

  &__headline {
    @include headline3;
    margin-bottom: 60px;
    padding-left: 5%;

    @include medium-up {
      text-align: center;
      padding-left: 0;
    }
  }

  /* Modiefiers */

  &--news {
    padding: 100px 0;
    .slick-slider {
      &:before {
        content: '';
        position: absolute;
        height: 100%;
        width: 100vw;
        background: #f3f3f3;
        right: 1140px;
        z-index: 1;
      }

      &:after {
        content: '';
        position: absolute;
        height: 100%;
        width: 100vw;
        background: rgba(243, 243, 243, 0.5);
        left: 1140px;
        z-index: 1;
        top: 0;
      }
    }

    &--two {
      @include medium-up {
        text-align: center;
        // padding: 0 30%;
      }
    }

    .slick-dots {
      @media screen and (min-width: 1260px) {
        display: none !important;
      }
    }

    #{$ns} {
      &__slide {
        //min-width: 350px;

        a {
          position: relative;
          // max-width: 90%;
          display: block;
          margin: 0 auto;

          @include medium-up {
            max-width: 280px;
          }
        }
      }
    }
  }
}

.slick-list {
  outline: none !important;
}