// --- Colors ----------------------------------------
$black: #24272a;
$lightBlue: #00A0DF;
$color-blue-grey: #DFE5EA;
$color-light-grey: #F3F3F3;
$color-grey: #646669;
$color-error: #D6283D;

// --- Grid ----------------------------------------
$gridWidth: 1080px;

// --- Transitions ----------------------------------------
$transition: 300ms;

// --- Breakpoints ----------------------------------------
$x-small: 480px;
$bp-x-small-down: 'max-width: #{$x-small - 1px}';
$bp-x-small-up: 'min-width: #{$x-small}';

$small: 768px;
$bp-small-down: 'max-width: #{$small - 1px}';
$bp-small-up: 'min-width: #{$small}';

$medium: 1024px;
$bp-medium-down: 'max-width: #{$medium - 1px}';
$bp-medium-up: 'min-width: #{$medium}';

$large: 1300px;
$bp-large-down: 'max-width: #{$large - px}';
$bp-large-up: 'min-width: #{$large}';
