$ns: '.news-list';

#{$ns} {
  padding: 100px 0 0;

  @include medium-up {
    // padding: 180px 0;
    padding: 180px 0 70px 0;
  }
  &__headline {
    @include headline3;
    margin: 0 0 60px;

    @include medium-down {
      margin: 0 0 30px;
    }
  }

  &__title {
    @include subheadline1;
    color: $black;
    // margin-top: 20px;
    // margin-bottom: 10px;

    @include link-hover;

    @include small-down {
      @include subheadline3;
    }
    max-width: 280px;
    margin-bottom: 75px;
    text-align: left;
  }

  &__category {
    @include eyebrow;

    &--item {
      cursor: pointer;
      width: fit-content;

      @include medium-down {
        padding-bottom: 10px;
      }

      &.active {
        color: $lightBlue;
        @include animation-border-bottom($lightBlue);
      }
      @include animation-border-bottom(transparent);
    }

    &-title {
      @include subheadline3;
      @include link-hover;

      @include small-down {
        @include eyebrow;
        padding-top: 25px;
      }
    }
  }

  &__eyebrow {
    @include bodyCopySmall;
    margin-top: 10px;
    color: #646669;
    text-align: left;

    a {
      color: #646669;
    }

    &--link {
      @include link-hover;
    }
  }

  &__filter {
    height: 39px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 45px;

    @include subheadline3;

    @include medium-up {
      display: none;
    }

    &--button {
      background: url('https://prismic-io.s3.amazonaws.com/alliance-consumer-growth%2F9be44a6a-334a-4a0e-9d17-26219516024e_open.svg');
      width: 10px;
      height: 10px;
      cursor: pointer;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        width: 40px;
        height: 40px;
        left: -10px;
      }

      &.mobile-menu-open {
        background: url('https://prismic-io.s3.amazonaws.com/alliance-consumer-growth%2Ffc6f456b-3379-49de-8e94-778f9e8b4bd2_close.svg');
        height: 2px;
      }
    }
  }

  &__header {
    display: flex;
    width: 100%;
    justify-content: space-between;

    @include medium-down {
      padding-top: 50px;
      display: block;
    }
  }

  &__categories {
    display: flex;
    width: 330px;
    justify-content: space-between;

    &--category-title {
      @include eyebrow;
      color: $lightBlue;
    }

    @include medium-down {
      display: none;
    }

    &.mobile-menu-open {
      display: flex;
      flex-direction: column;
      padding: 0;
      width: 200px;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    padding: 0;

    &--none {
      @include subheadline3;
      height: 50vh;
      padding: 10% 30%;

      @include small-down {
        padding: 5% 1%;
      }
    }

    @media screen and (max-width: 741px) {
      display: block;
    }
  }

  &__list--item {
    flex-basis: 280px;

    &:not(:nth-child(3n)) {
      @include small-up {
        padding-right: 120px;
      }

      @media screen and (max-width: 889px) {
        padding-right: 8%;
      }
    }

    @media screen and (max-width: 1200px) {
      &:not(:nth-child(2n)) {
        @include small-up {
          padding-right: 120px;
        }

        @media screen and (max-width: 889px) {
          padding-right: 8%;
        }
      }
    }

    img {
      width: 280px;
      height: 348px;
      object-fit: cover;
    }
  }

  &__image {
    transform: scale(1);
    transition: transform 2s cubic-bezier(0.25, 0.46, 0.45, 0.94);

    &-wrapper {
      overflow: hidden;
    }

    &:hover {
      transform: scale(1.2);

      @include medium-down {
        transform: none;
      }
    }
  }
}
