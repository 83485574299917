$ns: '.page-home';
$mobile-padding: 100px;

#{$ns} {
  background: $color-blue-grey;

  .hero {
    &__headline {
      @include headline1;

      @include medium-up {
        padding-left: 28%;
      }
    }

    &__headline,
    &__copy,
    a {
      @include medium-up {
        padding-left: 18%;
      }
    }

    .grid-container {
      @include medium-up {
        padding-left: 220px;
      }

      //max-width: 730px;
      padding-top: 280px;
    }

    &--home {
      // position: relative;
      padding-bottom: 136px;

      @media screen and (min-width: 2013px) {
        margin-top: -1227px;
        padding-right: 273px;
      }

      .hero__headline {
        color: $black;
      }
    }

    p {
      color: $color-light-grey;
      padding-bottom: 23px;

      @include small-down {
        padding-bottom: 23px;
      }
      padding-top: 12px;

      @include bodyCopyMed;
      max-width: 580px;
      margin: 15px 0;
    }

    a {
      margin-bottom: 100px;

      @include medium-up {
        margin-bottom: 0;
      }
    }
  }

  .home-slider {
    max-width: none;
    margin: none;
  }

  &__slideshow-wrapper {
    max-width: 1080px;
    margin: 0 auto;
  }

  &__header-wrapper {
    background: $color-light-grey;
    z-index: 20;
    // padding-bottom: 44px;
  }

  &__headline-3 {
    @include headline3;
    // margin-bottom: 30px;

    &-focus {
      padding-bottom: 30px;
      margin-bottom: 30px;
    }

    &-about {
      margin-bottom: 14px;
      position: relative;
      z-index: 20;
    }

    &-news {
      margin-bottom: 30px;

      @include small-down {
        margin-bottom: 18px;
      }
    }
  }

  &__copy {
    @include copy;
    padding: 15px 0 23px 0;
  }

  &__about {
    background: $color-blue-grey;
    position: relative;
    padding: 100px 0;
    // z-index: 10;

    @include small-up {
      margin: 58px 0;
    }

    &--info-wrapper {
      max-width: 621px;
    }

    &-copy {
      @include bodyCopyMed;
      @include info-padding;
    }

    &-wrapper {
      overflow: hidden;
      position: relative;
    }
  }

  [class^='page-home__triangle'] {
    // position: absolute;
    // width: 100%;
    // // z-index: -1;
    // top: -242px;
    // // transform: scale(0.4);
    // right: -125px;

    // @include medium-up {
    //   max-width: 440px;
    //   top: -355px;
    // }

    // &.page-home__triangle--full {
    //   top: 0;
    //   // left: 0;

    //   @media screen and (min-width: 1439px) {
    //     // left: 210px;
    //   }
    // }
  }

  &__triangle {
    position: absolute;
    // margin-top: 180px;
    // z-index: 2 !important;
    // z-index: -1;
    left: 0;
    width: 494px;
    height: 535px;
    top: 1670px;

    @media screen and (min-width: 1220px) {
      top: 1750px;
    }

    @media screen and (min-width: 2013px) {
      top: 1670px;
    }

    @include medium-down {
      display: none;
    }

    &2 {
      width: 494px;
      height: 535px;
      // position: absolute;
      // right: -40px;
      // top: -330px;
      z-index: 1;

      @media screen and (max-width: 1145px) {
        zoom: 0.8;
      }

      @media screen and (min-width: 1439px) {
        right: -167px;
      }

      &-wrapper {
        position: absolute;
        top: -278px;
        right: 0;

        @include small-down {
          zoom: 40%;
          position: absolute;
          right: 0px;
          top: -268px;
        }
      }
    }
  }

  &__focus {
    &-background {
      position: relative;
      max-width: 1440px;
      margin: 0 auto;
      z-index: 5;
      &:before {
        // content: '';
        // width: 100%;
        // height: 150px;
        // left: 0;
        // bottom: 0;
        // background: #fff;
        // position: absolute;
        // z-index: 0;
      }

      .grid-container {
        @include small-down {
          max-width: 94.7%;
          padding: 0 0 0 20px;
        }
      }
    }

    &-wrapper {
      z-index: 10;
    }

    &-list {
      //@include info-padding;
      display: none;
      @include small-up {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0;
        margin: 0;
        z-index: 10;
      }

      > a {
        @include small-up {
          margin-bottom: 50px;
          overflow: hidden;
          width: 48%;
          margin-right: 4%;
          z-index: 10;
          color: white;

          &:nth-child(2n + 2) {
            margin-right: 0;
          }
        }
        @include medium-up {
          width: 30%;
          margin-right: 5%;
          z-index: 10;
          color: white;

          &:nth-child(2n + 2) {
            margin-right: 5%;
          }

          &:nth-child(3n + 3) {
            margin-right: 0;
          }
        }
      }
    }

    &-list-item {
      width: 100%;
      height: 220px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      @include subheadline1;
    }

    &--bg {
      width: 100%;
      height: 100%;
      // @include animation-image-hover;
      @include animation-img;
      background-repeat: no-repeat;
      background-size: cover;

      @include medium-down {
        &:hover {
          background-color: transparent;
          transform: none;
        }
      }
    }

    &-list-title {
      position: absolute;
    }

    //Carousel
    &-list2 {
      display: none;
      width: 300px;

      &-item2 {
        width: 320px;
        height: 220px;
        background-size: cover;
      }

      &-title2 {
        height: 220px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        @include subheadline1;
      }

      a {
        &:not(:last-child) {
          margin-right: 40px;
        }

        margin-bottom: 40px;
      }
    }
  }
  .white-section {
    height: 150px;
    background: white;
    margin-top: -150px;

    @include small-down {
      height: 0px;
      background: white;
      margin-top: 4px;
    }
  }

  &__news {
    &-background {
      background: white;

      .grid-container {
        @include small-down {
          max-width: 94.7%;
          padding: 0 0 0 20px;
        }
      }
    }

    &-wrapper {
      padding-top: 110px;
      max-width: 1080px;
      margin: 0 auto;
      padding-bottom: 7%;
    }

    &-list {
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      max-width: 1080px;
      justify-content: space-between;

      a {
        position: relative;
        z-index: 10;
        margin: 0 0 80px;
        width: 100%;

        @include small-up {
          width: 48%;
          margin-right: 4%;

          &:nth-child(2n + 2) {
            margin-right: 0;
          }
        }

        @include medium-up {
          width: 28%;
          margin-right: 8%;

          &:nth-child(3n + 3) {
            margin-right: 0;
          }

          &:nth-child(2n + 2) {
            margin-right: 8%;
          }

          &:nth-last-child(-n + 3) {
            margin-bottom: 0;
          }
        }

        margin-top: 30px;
      }

      &-item {
      }

      &-image {
        width: 280px;
        height: 348px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        @include small-up {
          width: 100%;
        }

        @include medium-down {
          transform: none !important;
        }

        &-wrapper {
          overflow: hidden;
        }

        // @include animation-image-hover;
        @include animation-img;
      }
    }

    &--header-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
    }

    &--title {
      @include subheadline3;
      color: $black;
      margin-top: 35px;
      margin-bottom: -11px;

      @include link-hover;
    }

    &--source {
      @include bodyCopySmall;
      color: $color-grey;
      margin-bottom: 18px;

      @include link-hover;
    }

    &--description {
      @include subheadline1;
      color: $black;

      &:hover {
        color: $lightBlue;
        transition: color 0.3s ease;
      }

      @include medium-down {
        width: 280px;
      }
    }
  }

  &--controls {
    display: none;

    .slick-slide,
    .slick-slide.slick-active,
    &.slick-current {
      @media screen and (min-width: 380px) {
        width: 340px !important;
      }
    }

    &:nth-of-type(2) {
      padding-bottom: 20px;

      .slick-slide,
      .slick-slide.slick-active,
      &.slick-current {
        @media screen and (min-width: 380px) {
          width: 300px !important;
        }
      }
    }

    &-button {
      &:not(:last-child) {
        padding-right: 5px;
      }
    }
  }

  //Media Queries

  @media screen and (max-width: 1220px) {
    .hero {
      margin-top: -393px;
      &__headline {
        @include headline1-mobile;
      }
    }

    &__headline-3 {
      @include headline3-mobile;
    }
  }

  @media screen and (max-width: 890px) {
    .hero {
      .grid-container {
        // padding-top: 410px;
        // padding-top: 358px;
        padding-top: 344px;
      }
      padding-bottom: 0px;
    }
  }

  @include small-down {
    &__focus {
      // &-wrapper {
      //   margin-top: -200px;
      //   position: relative;
      // }

      // &-list {
      //   display: none;
      // }

      &-list2 {
        display: flex;
        padding: 0;
        margin-bottom: -22px;
      }
    }

    .hero {
      .grid-container {
        padding-left: 20px;
      }
    }

    &--controls {
      display: inherit;
      // overflow: hidden;
      overflow: visible;
    }

    &__triangle {
      // display: none;
      z-index: 1;
    }

    &__about {
      padding-top: $mobile-padding;

      &-wrapper {
        max-width: 1440px;
        margin: 0 auto;
      }
    }

    &__news {
      &-list {
        display: none;
        flex-wrap: nowrap;
        overflow-x: auto;
        width: 300px;
      }

      &-wrapper {
        padding-top: 290px;
        padding-bottom: 26%;
        margin-top: -170px;
      }

      &--header-wrapper {
        display: inline;
      }

      &--link {
        padding-bottom: 20px;
      }
    }

    .slick {
      &-dots {
        text-align: left;
      }

      &-custom-pagination {
        width: 20px;
        height: 2px;
        background: $black;
        margin-top: 20px;
        transition: background 0.3s ease;
      }

      &-active {
        & > a > div {
          background: $lightBlue;
        }
      }

      // &-slide, &.slick-active, &.slick-current {
      //   width: 340px !important;
      // }
    }
  }
}
