$ns: '.partner-list';

#{$ns} {
  background: white;
  padding: 30px 0 100px;
  @media screen and (min-width: 1300px) {
    padding: 80px 0;
  }

  @include small-down {
    padding: 30px 0 0;
  }

  .partner-wrapper {
    // max-width: 1080px;
    margin: 0 auto;
  }

  &__headline {
    @include headline3;
    margin: 0 0 60px;
  }

  &__title {
    @include subheadline1;
    color: $black;
    margin-bottom: 10px;
  }

  &__position {
    @include bodyCopy;
  }

  &__status {
    &-wrapper {
      display: flex;
      width: 258px;
      align-items: baseline;

      @include eyebrow;

      p {
        color: $lightBlue;
        padding-right: 20px;
      }

      li {
        @include animation-border-bottom(transparent);
        cursor: pointer;
        color: $color-grey;

        &.active {
          @include animation-border-bottom($lightBlue);
          color: $lightBlue;
        }
      }
    }
  }

  &__list {
    max-width: 1080px;
    padding: 120px 0 50px 0;
    margin: 0 auto !important;
    min-height: 535px;
    text-align: center;

    @include medium-down {
      padding-left: 20px;
      padding-right: 20px;
    }

    @include small-down {
      padding: 100px 20px 0px 20px;
    }

    &--none {
      @include subheadline3;

      display: flex;
      justify-content: center;
    }

    &--item {
      max-width: 207px;
      height: 117px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-right: 3%;
      padding-left: 3%;
      display: inline-block;

      // &:not(:nth-last-child(-n+4)) {
      padding-bottom: 100px;
      // }

      &:nth-child(4n + 4) {
        padding-right: 0;
      }

      @include small-down {
        width: 170px;
      }
    }

    // flex-direction: column;
    &--image {
      width: 100%;
      filter: grayscale(100%);
      opacity: 0.7;
      transition: all 0.3s ease;

      &:hover {
        filter: none;
        opacity: 1;
      }
    }
  }

  &__focus {
    position: relative;
    padding-right: 20px;

    &-wrapper {
      display: flex;
      // width: 551px;
      width: 100%;
      // padding-left: 40px;
      padding-left: 20px;
      align-items: baseline;
      @include eyebrow;

      p {
        color: $lightBlue;
      }

      li {
        @include animation-border-bottom(transparent);
        cursor: pointer;
        color: $color-grey;

        &.active {
          @include animation-border-bottom($lightBlue);
          color: $lightBlue;
        }
      }
    }
  }

  &__filter {
    height: 39px;
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    @include subheadline3;
    border-bottom: 1px solid #e9e9e9;
    position: relative;
    margin-top: -30px;
    padding-top: 30px;

    &:hover {
      cursor: pointer;
    }

    @media screen and (min-width: 1300px) {
      display: none;
    }

    &--text {
      margin-top: -7px;
    }

    &--button {
      background: url('https://prismic-io.s3.amazonaws.com/alliance-consumer-growth%2F9be44a6a-334a-4a0e-9d17-26219516024e_open.svg');
      width: 10px;
      height: 10px;

      &.mobile-menu-open {
        background: url('https://prismic-io.s3.amazonaws.com/alliance-consumer-growth%2Ffc6f456b-3379-49de-8e94-778f9e8b4bd2_close.svg');
        height: 2px;
      }
    }
  }

  &__header {
    display: flex;
    // max-width: 812px;
    // max-width: 950px;
    // max-width: 1080px;
    // margin: 0 auto;

    @media screen and (max-width: 1300px) {
      display: none;
    }

    &-wrapper {
      display: flex;
      justify-content: center;

      @media screen and (max-width: 1300px) {
        background: $color-light-grey;
      } 
    }

    &.mobile-menu-open {
      display: flex;
      background: $color-light-grey;
      max-width: none;

      .partner-list__focus:before {
        position: relative;
      }

      .partner-list__categories {
        flex-direction: column;
      }

      .partner-list__category {
        padding-bottom: 10px;
      }
    }
  }

  &__category {
    &--item {
      padding-right: 20px
    }
  }

  &__categories {
    display: flex;
    // justify-content: space-between;
    justify-content: flex-start;
    width: 100%;
    align-items: baseline;

    &--status {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        background: #979797;
        height: 27px;
        width: 1px;
        // right: 78px;
        right: -5px;
        top: 10px;
      }
    }

    &--focus {
      // justify-content: flex-start;
    }
  }
}
