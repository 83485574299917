$ns: '.page-contact';

#{$ns} {
  .hero {
    &__link {
      color: $lightBlue;
      width: fit-content;
      border-bottom: 1px transparent solid;
      transition: border-bottom 0.3s ease;

      &:hover {
        border-bottom: 1px $lightBlue solid;
      }

      @include small-down {
        @include bodyCopyMed;
        color: $lightBlue;
      }
    }

    &__title {
      @include small-down {
        @include subheadline3;
      }
    }

    &--contact {
      // padding: 320px 0 257px;
      .hero__headline {
        color: $black;
      }
    }

    &__list {
      @include medium-down {
          padding-bottom: 106px;
      }
    }

    position: relative;
    z-index: 23;
  }

  [class^='page-contact__triangle'] {
    position: absolute;
  }

  &__triangle {
    top: 45%;
    @media screen and (max-width: 1326px) {
      // left: -27%;
      display: none;
    }

    &2 {
      display: block;
      top: -50px;
      right: 0;
      max-width: 220px;

      @include small-up {
        top: -200px;
        max-width: 100%;
      }
    }
  }
}
