$ns: '.team-detail';

#{$ns} {
  padding: 130px 0 30px;
  @include medium-up {
    padding: 153px 0 170px;
  }

  &__grid {
    display: block;

    @include medium-up {
      display: flex;
    }
  }

  &__col {
    width: 100%;

    &:first-child {
      margin-bottom: 45px;
    }

    @include medium-up {
      &:first-child {
        max-width: 350px;
        margin-bottom: 0;
      }

      &:last-child {
        margin-left: 120px;
        width: 68%;
      }
    }
  }

  img {
    position: relative;
    width: 100%;
  }

  &__headline {
    @include headline1;
    margin-bottom: 9px;

    @include small-down {
      @include headline1-mobile;
      margin-bottom: 20px
    }
  }

  &__position {
    @include subheadline1;
    position: relative;
    color: $black;
    margin-bottom: 30px;

    @include small-down {
      @include subheadline3;
    }
  }

  &__bio {
    @include bodyCopyMed;
  }

  &__link {
    @include eyebrow;
    color: $black;
    position: relative;
    display: block;
    padding-left: 10px;
    margin-bottom: 50px;
    @include medium-up {
      margin-bottom: 100px;
    }

    // &:before {
    //   content: url(https://prismic-io.s3.amazonaws.com/alliance-consumer-growth%2F77ba1c2d-ee04-46bf-8d4f-f9d3bed31d37_left-arrow.svg);
    //   position: absolute;
    //   left: 0;
    //   top: -1px;
    // }

  }
  
  &__back {
    display: flex;
    justify-content: flex-start;
    height: 20px;
    margin-bottom: 96px;

    @include small-down {
      margin-bottom: 45px;
    }
    
    &:hover {
      .team-detail__link {
        color: $lightBlue;
        transition: color 0.3s ease;
      }

      svg path {
        fill: $lightBlue;
      }
    }

    svg path {
      fill: $black;
      transition: fill 0.3s ease;
    }
  }
}
