$ns: '.copy-list';

#{$ns} {
  visibilty: hidden;
  padding: 50px 0 80px;
  background: #fff;

  &__eyebrow {
    @include eyebrow;
    color: $lightBlue;
    margin: 0 0 65px;
  }

  &__headline {
    @include headline3;
    margin: 0 0 40px;

    @include small-up {
      margin: 0 0 100px;
    }
  }

  &__wrapper {
    position: relative;
    padding: 0;
    list-style: none;
    max-width: 650px;
    display: none;
    // counter-reset: section;

    @include small-up {
      display: flex;
      flex-wrap: wrap;
      max-width: 100%;
    }

    &--item {
      position: relative;
      margin: 0 0 60px;
      width: 100%;
      z-index: 10;
      color: white;

      @include small-up {
        display: flex;
        flex-flow: column;
        width: 48%;
        margin-right: 4%;

        &:nth-child(2n + 2) {
          margin-right: 0;
        }
      }
      // &:before {
      //   @include fontReg;
      //   counter-increment: section;
      //   content: counter(section, decimal-leading-zero);
      //   position: absolute;
      //   left: -50px;
      //   top: 23px;
      //   text-decoration: underline;
      //   font-size: 12px;
      // }
    }
  }

  .slick {
    &-dots {
      text-align: left;
    }

    &-custom-pagination--about {
      width: 20px;
      height: 2px;
      background: white;
      margin-top: 20px;
      transition: background 0.3s ease;
    }

    &-active {
      & > a > div {
        background: $lightBlue;
      }
    }
  }

  &__slider {
    @include small-up {
      display: none;
    }

    // .slick-dots {
    //   text-align: left;
    //   li {
    //     height: 3px;
    //     button {
    //       height: 3px;
    //       padding: 0;
    //       transition: $transition;
    //       &:hover {
    //         &:before {
    //           background: $lightBlue;
    //         }
    //       }
    //       &:before {
    //         content: '';
    //         transition: $transition;
    //         background: white;
    //         opacity: 1;
    //         width: 22px;
    //         height: 3px;
    //       }
    //     }

    //     &.slick-active {
    //       button:before {
    //         background: $lightBlue;
    //         opacity: 1;
    //       }
    //     }
    //   }
    // }
  }

  &__title {
    // @include fontBold;
    color: $black;
    // font-size: 32px;
    // letter-spacing: 0.9px;
    // line-height: 52px;
    // margin: 0 0 10px;

    @include headline3-mobile;

    @include small-down {
      @include subheadline3;
    }
  }

  &__copy {
    @include bodyCopy;

    @include small-down {
      @include bodyCopyMed;
    }
  }

  /* Modifiers */
  &--values {
    #{$ns} {
      &__wrapper {
        display: block;

        @include small-up {
          display: flex;
        }
        @include medium-up {
          margin-left: 240px;
        }
      }
    }
  }

  &--resources {
    // background: url(https://prismic-io.s3.amazonaws.com/alliance-consumer-growth%2F404be3ab-d71c-49c1-8627-f2ad1042716a_about-footer.png);
    background-size: cover;
    background-repeat: no-repeat;
    padding: 100px 0 100px;
    max-width: 90%;
    margin: 0 auto 100px;

    @include small-up {
      padding: 150px 0 100px;
      max-width: 100%;
      margin: 0;
    }

    #{$ns} {
      &__wrapper--item {
        margin: 0 0 30px;

        &:focus {
          outline: none !important;
        }

        @include small-up {
          display: flex;
          flex-flow: column;
          position: relative;
          margin: 0 0 60px;
          width: 30%;
          margin-right: 5%;
          z-index: 10;
          color: white;

          &:nth-child(3n + 3) {
            margin-right: 0;
          }

          &:before {
            top: 5px;
          }
        }
      }

      &__headline {
        color: white;
      }

      &__title {
        color: white;
        font-size: 20px;
        line-height: 24px;

        @include small-down {
          @include subheadline3;
          color: white;
        }
      }
      &__copy {
        color: white;
        max-width: 260px;

        @include small-down {
          @include bodyCopyMed;
          color: white;
        }
      }
    }
  }
}
