$ns: '.page-about';

#{$ns} {
  .hero {
    &:before {
      content: '';
      background: url(https://prismic-io.s3.amazonaws.com/alliance-consumer-growth%2F1a4d0cb7-cfc4-4555-bc69-6f7c7b0224ad_half-triangle2x.png);
      width: 440px;
      height: 275px;
      position: absolute;
      bottom: -275px;
      transform: rotateY(180deg) rotateZ(180deg);
      right: 0;
    }

    &__headline {
      @include headline1;

      @include small-down {
        @include headline1-mobile;
      }
    }

    z-index: 2;
  }

  .copy-block {
    background: $color-blue-grey;

    &__triangle {
      @include medium-down {
        display: block;
        top: -296px;
        right: 0px;
        position: absolute;
        // transform: scale(0.4);
        zoom: 40%;
      }
      z-index: 1;
    }
  }

  .copy-list {
    &--values {
      position: relative;
      background: $color-blue-grey;
      &:before {
        // content: '';
        // background: url(https://prismic-io.s3.amazonaws.com/alliance-consumer-growth%2F528b0e0c-5655-4e93-ae1d-ca490bfa6a7e_dotty-triangle.png);
        // width: 425px;
        // height: 575px;
        // position: absolute;
        // bottom: -275px;
        // left: 0;
      }
    }

    &__copy {
      @include bodyCopyMed;
    }

    &__title {
      @include subheadline1;
      &:nth-child(-n + 3) {
        @include headline3-mobile;
        padding-bottom: 35px;
      }
    }

    &--resources {
      .copy-list__title {
        @include subheadline1;
      }

      .copy-list__copy {
        @include bodyCopyMed;
        color: white;
      }
    }
  }

  [class^='page-about__triangle'] {
    position: absolute;
  }

  &__triangle {
    top: 1720px;

    @media screen and (max-width: 1140px) {
      display: none;
    }
    &2 {
      top: 462px;
      z-index: 1;
      right: 0;

      @include medium-down {
        top: 447px;
        transform: scale(0.5);
        right: -107px;
      }
    }
  }

  .split-columns {
    padding: 100px 0 40px;

    &__title {
      @include small-down {
        @include subheadline3;
      }
    }

    &__copy {
      @include small-down {
        @include bodyCopyMed;
      }
    }
  }

  .grid-container {
    position: relative;
    z-index: 20;
  }
}
