$ns: '.partner-copy-block';

#{$ns} {
  background: #fff;
  padding: 100px 0;

  @include medium-up {
    padding: 170px 0;
  }

  .grid-container {
    display: block;

    @include medium-up {
      display: flex;
    }
  }

  &__col {
    width: 100%;

    &:last-child {
      padding-top: 60px;
    }

    @include medium-up {
      &:last-child {
        width: 30%;
        padding-top: 0;
      }

      &:first-child {
        width: 70%;
      }
    }
  }

  &__description {
    @include bodyCopyBig;
    max-width: 700px;
    span {
      display: block;
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 60px;
      }
    }

    @include small-down {
      @include bodyCopyMed;
    }
  }

  &__side {
    &-copy {
      @include bodyCopyMed;
      line-height: 30px;
    }
    &-title {
      @include bodyCopySmall;
    }
  }

  &__social-text a {
    color: $lightBlue;
    line-height: 30px;
  }

  &__block-member {
    &:not(:last-of-type) {
      padding-bottom: 35px;
    }
  }
}
