@mixin animation-border-bottom-nav($color, $width) {
  display: inline-block;
  backface-visibility: hidden;
    
  &:after {
    @include border-bottom($color);
    content: "";
    display: block;
    width: $width;
    margin: 0 auto;
    // transition: border-bottom 0.3s ease;
  }

  &:hover {
    color: $lightBlue;
    transition: color 0.2s ease-in-out;
    
    &:after {
      transition: width 0.3s ease-in-out;
      margin: 0 auto;
      width: 20px;
      @include border-bottom($lightBlue);
    }
  }
}

@mixin animation-image-hover {
  transform: scale(1);
  transition: transform 2s cubic-bezier(0.25, 0.46, 0.45, 0.94), background-color 2s ease-in-out;
  background-color: transparent;

  &:hover {
    transform: scale(1.2);
    background-color: $lightBlue;
    background-blend-mode: multiply;
  }
}

@mixin animation-img {
  transform: scale(1);
  transition: transform 2s cubic-bezier(0.25, 0.46, 0.45, 0.94);

  &-wrapper {
    overflow: hidden;
  }

  &:hover {
    transform: scale(1.2);
  }
}

@mixin animation-border-bottom($color) {
  display: inline-block;
  backface-visibility: hidden;
    
  &:after {
    @include border-bottom($color);
    content: "";
    transform: scaleX(1);
    transition: transform 0.3s ease-in-out;
    display: block;
  }
   &:hover {
    color: $lightBlue;
    transition: color 0.2s ease-in-out;
    
    &:after {
      transform: scaleX(0.2);
      transition: transform 0.3s ease-in-out,
      border-bottom 0.3s ease-in-out;
      @include border-bottom($lightBlue);
    }
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 20%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  animation-name: fadeInUp;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(20%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInRight {
  animation-name: fadeInRight;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(2);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -30%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}