$ns: '.header';

$button-height: 16px;
$button-width: 20px;

body.mobile-menu-open {
  overflow: hidden;
  position: relative;
  height: 100%;
}

#{$ns} {
  background: #fff;
  width: 100%;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: background-color 250ms ease;
  z-index: 400;
  transition: none;

  .menu--pull-up & {
    position: fixed;
  }

  .menu--open & {
    transform: translate3d(0, 0, 0) !important;

    #{$ns} {
      &__logo {
        margin: 20px 0;
      }
      &__logo--main {
        display: none;
        opacity: 0;
      }

      &__logo--scroll {
        display: block;
        opacity: 1;
        max-width: 150px;
        width: 148px;
        height: 44px;
      }

      &--transparent {
        background: #fff;
      }
    }

    &--transparent {
      background: #fff !important;
    }
  }

  &.transition {
    transition: all 250ms ease;
  }

  @media screen and (max-width: 1024px) {
    transform: none !important;
  }

  &--transparent {
    background: transparent;
    transition: all 250ms ease;
  }

  &--index {
    .header__logo {
      margin: 65px 0;
      max-width: 200px;
    }
  }

  &__container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__logo {
    max-width: 150px;
    margin: 30px 0;
    transition: 325ms;

    &--image {
      position: relative;
      // width: 100%;
      width: 157px;
      height: 44px;
    }

    &--scroll {
      display: none;
      opacity: 0;
      transition: 250ms;
    }
  }

  &__menu {
    display: flex;
    align-items: center;
    @include subheadline3;
    z-index: 200;

    &--desktop {
      padding: 0;
      display: none;
      list-style: none;
      justify-content: center;
      width: 100%;
      margin: 0;
    }

    &--item {
      &.active a {
        color: $lightBlue;
        @include animation-border-bottom-nav($lightBlue, 20px);
      }
    }

    &--mobile {
      &-button {
        display: block;
        background: transparent;
        position: relative;
        top: 5%;
        right: 0;
        height: $button-height;
        width: $button-width;
        cursor: pointer;
        transition: opacity 0.3s ease;
        z-index: 200;

        span {
          background: $black;
          height: 2px;
          width: 100%;
          position: absolute;
          transition: all 0.3s ease;
          cursor: pointer;

          &:nth-of-type(2) {
            top: 7px;
          }

          &:nth-of-type(3) {
            top: 14px;
          }
        }

        &:hover {
          opacity: 0.7;
        }

        &.active {
          .header__menu--mobile-button-top {
            transform: translateY(7px) translateX(0) rotate(45deg);
            background: white;
          }

          .header__menu--mobile-button-middle {
            opacity: 0;
            background: white;
          }

          .header__menu--mobile-button-bottom {
            transform: translateY(-7px) translateX(0) rotate(-45deg);
            background: white;
          }
        }
      }

      &-overlay {
        visibility: hidden;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background: $black;
        display: flex;
        align-items: center;
        transition: 250ms;
        opacity: 0;

        &.open {
          opacity: 1;
          visibility: visible;
          opacity: 1;
        }
      }

      &-item {
        a {
          color: white;
          @include mobile-menu;
        }
      }
    }
  }

  &__link {
    color: $black;
    padding: 0 10px 4px;
    position: relative;
    display: block;

    @include animation-border-bottom-nav(transparent, 100%);
  }

  @include medium-up {
    &__menu {
      &--desktop {
        display: flex;
      }

      &--mobile {
        &-button {
          display: none;
          // transition: display 7s ease;
        }
      }
    }

    &.home-slideshow {
      top: -90px;
      left: -126px;
      transform: scale(0.6);
    }
  }
}
