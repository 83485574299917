$ns: '.page-partner-detail';

#{$ns} {
  .hero__link--arrow {
    @include eyebrow;
    color: white;
    position: relative;
    display: block;
    padding-left: 10px;
    margin-bottom: 50px;
    @include medium-up {
      margin-bottom: 180px;
    }

    // &:before {
    //   content: url(https://prismic-io.s3.amazonaws.com/alliance-consumer-growth%2F26b21f56-a0d4-4ac5-a213-316834290618_back-arrow.svg);
    //   position: absolute;
    //   left: 0;
    //   top: -1px;
    // }

    // &:hover {
    //   @include link-hover;
    // }
  }

  .hero__back {
    display: flex;
    justify-content: flex-start;
    height: 20px;
    margin-bottom: 166px;

    @include small-down {
      margin-bottom: 45px;
      position: absolute;
      top: 34px;
    }
    
    &:hover {
      .hero__link--arrow {
        color: $lightBlue;
        transition: color 0.3s ease;
      }

      svg path {
        fill: $lightBlue;
      }
    }

    svg path {
      fill: white;
      transition: all 0.3s ease;
    }
  }

  .hero__copy {
    @include subheadline1;
    padding-top: 12px;

    @include small-down {
      @include subheadline3;
      color: white;
    }
  }

  // @media (min-width: 1024px) {
    .hero .grid-container {
      padding-top: 53px;
    }
  // }

  @include small-down {
    .hero .grid-container {
      padding-top: 250px;
    }
  }

  .slider-fancy {
    padding-bottom: 165px;

    &--news {
      button {
        top: 174px;
      }
    }
  }

  .news-list__image {
    transform: none;
  }
}
