$ns: '.locations';

#{$ns} {
  padding: 210px 0 250px;
  background: #fff;
  position: relative;

  @include small-down {
    padding: 100px 0 100px;
  }

  .grid-container {
    display: block;

    @include medium-up {
      display: flex;
    }
  }

  &__col {
    width: 100%;

    &:first-child {
      margin-bottom: 100px;
    }

    @include medium-up {
      display: 44%;

      &:first-child {
        margin-bottom: 0;
        margin-right: 12%;
      }

      &:last-child {
        margin-right: 0%;
      }
    }
  }

  &__list {
    padding: 0;
    list-style: none;

    &--item {
      margin-bottom: 60px;
      max-width: 385px;
    }
  }

  &__city-wrapper {
    &:hover {
      #{$ns} {
        &__city {
          transform: scale(1.2);
          @include medium-down {
            transform: none;
          }

          p {
            transform: scale(0.8);
            @include medium-down {
              transform: none;
            }
          }
        }
      }
    }
  }

  &__city {
    height: 420px;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: url(https://prismic-io.s3.amazonaws.com/alliance-consumer-growth%2Febf4dfc3-e193-42a7-8f54-2a9f94369a69_harrys_hero.png);
    // margin-bottom: 50px;
    background-repeat: no-repeat;
    background-size: cover;
    transition: transform 2s cubic-bezier(0.25, 0.46, 0.45, 0.94), background-color 2s ease-in-out;

    @include medium-up {
      height: 650px;
    }

    p {
      @include headline2;
      font-size: 44px;
      line-height: 54px;
      color: #fff;
      position: absolute;
      z-index: 11;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      @include small-up {
        font-size: 60px;
        line-height: 70px;
      }
    }

    
    &-wrapper {
      overflow: hidden;
      position: relative;

      &--new-york {
        p {
          max-width: 100px;
        }
      }

      &--los-angeles {
        p {
          max-width: 250px;
          text-align: center;
        }
      }

      p {
        @include headline2;
        font-size: 44px;
        line-height: 54px;
        color: #fff;
        text-align: center;
        position: absolute;
        z-index: 11;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @include small-up {
          font-size: 60px;
          line-height: 70px;
        }
      }
    }
  }

  &__address {
    @include bodyCopyMed;
    margin: 40px 0 15px;
    span {
      display: block;
    }
  }

  &__headline {
    @include headline3;
    margin-bottom: 60px;
  }

  &__title {
    @include subheadline1;
    margin-bottom: 30px;
  }

  &__copy {
    @include bodyCopy;
  }

  &__triangle {
    position: absolute;
    width: 100%;
    max-width: 220px;
    top: -150px;
    z-index: 0;
    left: 0;

    @include medium-up {
      max-width: 440px;
      top: -300px;
    }

    @media screen and (max-width: 1326px){
      display: none;
    }
  }

  a {
    @include animation-border-bottom($black);
    color: $black;
    @include eyebrow;
  }
}
