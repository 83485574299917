$ns: '.brand-list';

#{$ns} {

	max-width: 780px;
	margin: 0 auto;
	padding-bottom: 150px;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	height: 594px;
	@include padding-side;

	@include small-down {
		height: auto;
	}

	&__wrapper {
		padding-bottom: 19px;
		display: flex;
		max-width: 280px;
		justify-content: space-between;
		align-items: baseline;

		@include small-down {
			max-width: unset;
		}
	}

	&__link {
		@include subheadline1;
		@include animation-border-bottom(transparent);
		color: $black;
	}

	&__date {
		@include bodyCopySmall;
	}

	&__title {
		@include small-down {
			@include subheadline3;
		}
	}
}