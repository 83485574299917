$ns: '.copy-block';

#{$ns} {
  padding: 170px 0 50px;
  position: relative;
  background: #fff;
  &__copy {
    @include subheadline1;
    position: relative;
    z-index: 200;
    @include medium-up {
      @include headline3;
    }
    
    .page-about & {
      @include medium-up {
        margin-left: 240px;
      }
      &:before {
        @include subheadline1;
        content: 'Team';
        position: absolute;
        left: 0px;
        top: -60px;
        
        @include small-down {
          @include eyebrow;
        }

        @include medium-up {
          left: -240px;
          top: 0px;
        }
      }
    }

    &--eyebrow {
      padding-right: 188px;
      @include subheadline1;

      @include medium-down {
        padding-bottom: 47px;
        @include eyebrow;
      }
    }

    .page-about & {
      &:before {
        content: 'About';

        @include small-down {
          @include eyebrow;
        }
      }
    }
  }

  &__triangle {
    display: none;

    @include medium-up {
      display: block;
      position: absolute;
      top: -300px;
      right: 0;
    }
  }

  &--blue {
    background: $lightBlue;
    padding: 150px 0;

    #{$ns} {
      &__copy {
        text-align: center;
        color: #fff;
        margin: 0 auto;
        position: relative;
      }
    }
  }
}
