@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 130px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  animation-name: fadeInUp;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-130px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInLeft {
  animation-name: fadeInLeft;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(130px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInRight {
  animation-name: fadeInRight;
}

@keyframes tint {
  50% {
    border: 1px solid $lightBlue;
  }
}

@keyframes fill {
  50% {
    transform: scale(0.75);
  }
}

.loading {
  height: calc(100vh - 100px);
  background: #eee;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
  transition: 250ms;
}

.loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;
  padding: 2rem 0;

  &__dots__dot {
    width: 20px;
    height: 20px;
    background: transparent;

    display: inline-block;
    border: 1px solid $black;
    position: relative;
    margin-right: 10px;

    &:before {
      content: '';
      width: 18px;
      height: 18px;
      display: block;
      background: $lightBlue;
      position: absolute;
      transform: scale(0.5);
      top: 1px;
      left: 1px;
      transform-origin: center;
    }

    &:nth-child(1) {
      animation: tint 1200ms infinite;

      &:before {
        animation: fill 1200ms infinite;
      }
    }
    &:nth-child(2) {
      animation: tint 1200ms infinite 200ms;

      &:before {
        animation: fill 1200ms infinite 200ms;
      }
    }
    &:nth-child(3) {
      animation: tint 1200ms infinite 400ms;

      &:before {
        animation: fill 1200ms infinite 400ms;
      }
    }
  }
}

svg path,
svg rect {
  fill: $lightBlue;
}
