$padding-side: 20px;
$padding-side-small: 40px;
$padding-side-medium: 60px;
$padding-side-large: 180px;

@mixin grid(
	$span: 3,
	// how many columns the grid spans
		$gutters: $span - 1,
	// how many gutters the grid spans
		$cols: 12,
	// total columns in grid
		$gutter-width: 30px,
	// how wide gutters are between columns
		$property: width
) {
	// Do some math here intead of in the calc function
	$cols-width: ($cols - 1) * $gutter-width;
	$gutters-width: $gutters * $gutter-width;

	// The property were styling
	$property-name: #{$property};

	#{$property-name}: percentage(1 / $span);
	#{$property-name}: calc(((100% - #{$cols-width}) / #{$cols}) * #{$span} + #{$gutters-width});
}

@mixin padding-side {
	padding-left: $padding-side;
	padding-right: $padding-side;

	@media (min-width: 768px) {
		padding-left: $padding-side-small;
		padding-right: $padding-side-small;
	}

	@media (min-width: 1024px) {
		padding-left: $padding-side-medium;
		padding-right: $padding-side-medium;
	}

	@media (min-width: 1400px) {
		padding-left: $padding-side-large;
		padding-right: $padding-side-large;
	}
}

@mixin padding-side-nav {
	padding-left: $padding-side;
	padding-right: $padding-side;

	@media (min-width: 768px) {
		padding-left: 150px;
		padding-right: 150px;
	}
}

@mixin padding-side-home {
	padding-left: $padding-side;
	padding-right: $padding-side;

	@media (min-width: 768px) {
		padding-left: $padding-side-small;
		padding-right: $padding-side-small;
	}

	@media (min-width: 1024px) {
		padding-left: $padding-side-medium;
		padding-right: $padding-side-medium;
	}
}