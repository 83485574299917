.acg {
  body {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #f3f3f3;
    font-family: 'sharp-sans-display-light', arial, sans-serif;
  }

  a,
  a:visited {
    text-decoration: none;
  }

  .grid-container {
    width: $gridWidth;
    margin: 0 auto;
    max-width: 90%;
  }
}

li {
  list-style: none;
}
