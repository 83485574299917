$ns: '.hero';

#{$ns} {
  // padding: 230px 0 200px;
  // padding: 157px 0 88px;
  // background-size: cover;
  // background-repeat: no-repeat;
  position: relative;
  min-height: 600px;
  margin-top: 100px;
  overflow: hidden;

  .grid-container {
    padding-top: 220px;
    //margin-bottom: 40px;
    @include medium-up {
      padding-top: 160px;
      //margin-bottom: 60px;
    }
  }

  &__bg-image {
    // position: absolute;
    // left: 0;
    // top: 0;
    // width: 100%;
    // height: 100%;
    // -o-object-fit: contain;
    // object-fit: contain;

    position: absolute;
    width: 100%;
    z-index: -1;
    height: 100%;
    object-fit: cover;

    @include small-down {
      filter: grayscale(0.5) contrast(50%);
    }
  }

  &__headline {
    @include headline1;

    @include small-down {
      color: white;
    }
  }
  &__eyebrow {
    @include fontBold;
    color: $lightBlue;
    margin: 0 0 65px;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    padding: 0;
    margin: 80px 0 0;
    flex-direction: column;

    @include medium-up {
      flex-direction: row;
    }
  }

  &__list--item {
    display: flex;
    flex-flow: column;
    position: relative;
    margin: 0 0 60px;
    width: 100%;

    &:nth-child(3n + 3) {
      margin-right: 0;
    }

    @include medium-up {
      width: 30%;
      margin-right: 5%;
    }
  }

  &__title {
    @include subheadline1;
    margin-bottom: 10px;
  }

  &__link {
    @include bodyCopy;
  }

  &__main-link {
    @include subheadline2;
    @include animation-border-bottom($black);
  }

  /* Modifiers */
  // &--contact {
  //   padding: 320px 0 200px;
  // }

  &--white {
    #{$ns} {
      &__category,
      &__headline,
      &__copy {
        color: #fff;
      }
    }
  }

  &--small {
    #{$ns} {
      &__headline {
        @include headline2;
      }
    }
  }
}
