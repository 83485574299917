// --- Media Queries --------------------------------------
@mixin x-small-down {
  // max-width: 479px
  @media ($bp-x-small-down) {
    @content;
  }
}
  
@mixin x-small-up {
  // min-width: 480px
  @media ($bp-x-small-up) {
    @content;
  }
}

@mixin small-down {
  // max-width: 767px
  @media ($bp-small-down) {
    @content;
  }
}

@mixin small-up {
  // min-width: 768px
  @media ($bp-small-up) {
    @content;
  }
}

@mixin medium-down {
  // max-width: 1023px
  @media ($bp-medium-down) {
    @content;
  }
}

@mixin medium-up {
  // min-width: 1024px
  @media ($bp-medium-up) {
    @content;
  }
}

@mixin large-down {
  // max-width: 1299px
  @media ($bp-large-down) {
    @content;
  }
}

@mixin large-up {
  // min-width: 1300px
  @media ($bp-large-up) {
    @content;
  }
}