$ns: '.page-partners';

#{$ns} {
  .hero {
    &__headline {
      @include headline3;
      max-width: 530px;
    }
  }
}
