// --- Fonts  ---------------------------------------------
@mixin fontReg {
  font-family: 'sharp-sans-display-light', arial, sans-serif;
}

@mixin fontBold {
  font-family: 'SharpSansDisplayNo1-Bold', arial, sans-serif;
}

@mixin fontSemiBold {
  font-family: 'SharpSansDisplayNo1-Semibold;', arial, sans-serif;
}

@mixin body-fontReg {
  font-family: 'NeueHaasUnicaPro-Regular', arial, sans-serif;
}

@mixin body-fontHeavy {
  font-family: 'NeueHaasUnicaPro-Heavy', arial, sans-serif;
}

@mixin body-fontLight {
  font-family: 'NeueHaasUnicaPro-Light', arial, sans-serif;
}

@mixin headline1 {
  @include fontBold;
  letter-spacing: 1px;
  margin: 0;
  font-size: 44px;
  line-height: 44px;
  @include medium-up {
    font-size: 90px;
    line-height: 90px;
  }
}

@mixin headline2 {
  @include fontBold;
  font-size: 60px;
  letter-spacing: 1px;
  line-height: 70px;
  margin: 0;
}

@mixin headline3 {
  @include fontBold;
  letter-spacing: 1px;
  margin: 0;
  font-size: 32px;
  line-height: 36px;

  @include medium-up {
    font-size: 44px;
    line-height: 54px;
  }
}

@mixin subheadline1 {
  @include fontSemiBold;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 1px;
  margin: 0;

  @include medium-up {
  }
}

@mixin subheadline2 {
  @include fontSemiBold;
  font-size: 18px;
  letter-spacing: 1px;
  line-height: 28px;
  color: $black;
}

@mixin subheadline3 {
  @include fontSemiBold;
  font-size: 16px;
  color: $black;
  letter-spacing: 1px;
  line-height: 26px;
}

@mixin eyebrow {
  @include fontSemiBold;
  font-size: 12px;
  letter-spacing: 1px;
  line-height: 22px;
  margin: 0;
}

@mixin bodyCopy {
  @include body-fontReg;
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 26px;
  color: $color-grey;
  margin: 0;
}

@mixin bodyCopyBig {
  @include body-fontReg;
  font-size: 20px;
  letter-spacing: 0px;
  line-height: 33px;
  color: $color-grey;
  margin: 0;
}

@mixin bodyCopyMed {
  @include body-fontReg;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 26px;
  color: $color-grey;
}

@mixin bodyCopySmall {
  @include body-fontReg();
  font-size: 12px;
  letter-spacing: 0;
  line-height: 22px;
}

@mixin disclaimer($opacity) {
  @include body-fontReg;
  font-size: 11px;
  opacity: $opacity;
  color: #ffffff;
  letter-spacing: 0;
  line-height: 21px;
}

@mixin headline1-mobile {
  @include fontBold;
  font-size: 44px;
  letter-spacing: 1px;
  line-height: 44px;
}

@mixin headline2-mobile {
  @include fontBold;
  font-size: 44px;
  letter-spacing: 1px;
  line-height: 54px;
}

@mixin headline3-mobile {
  @include fontBold;
  font-size: 32px;
  letter-spacing: 1px;
  line-height: 36px;
}

@mixin mobile-menu {
  @include fontSemiBold;
  font-size: 32px;
  color: #ffffff;
  letter-spacing: 1px;
  line-height: 70px;
}
