$ns: '.founders';

#{$ns} {
  padding: 100px 0;

  @include small-down {
    padding: 100px 0 12px 0;
  }
  position: relative;
  @include medium-up {
    padding: 180px;
  }
  background: #fff;
  &__headline {
    @include headline3;
    margin: 0 0 60px;
  }

  &__title {
    @include subheadline3;
    color: $black;
    margin-bottom: 10px;

    @include small-down {
      @include subheadline3;
    }
  }

  &__description {
    @include bodyCopy;
    max-width: 580px;
    margin-bottom: 100px;

    @include small-down {
      @include bodyCopyMed;
    }
  }

  &__company {
    @include bodyCopy;

    @include small-down {
      @include bodyCopyMed;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    // max-width: 100%;
    // margin: 0;
    padding: 0;
    // list-style: none;

    @include medium-down {
      display: block;
    }
  }

  &__list--item {
    // position: relative;
    // margin: 0 0 45px;
    // width: 100%;
    // margin-right: 5%;
    // z-index: 10;

    // @include medium-up {
      width: 180px;
      padding-bottom: 68px;
      margin-right: 6%;
      
      @include medium-down {
        width: auto;
      }
      
      
      @media screen and (min-width: 1558px){
        margin-right: 11%;
        &:nth-child(4n + 4) {
          margin-right: 0;
        }
  
        &:nth-last-child(-n + 4) {
          margin-bottom: 0;
        }
      }
    // }

    img {
      width: 100%;
      margin-bottom: 20px;
    }
  }

  &__triangle {
    display: none;

    @include medium-up {
      display: block;
      position: absolute;
      top: -300px;
      left: 0;
    }
  }
}
