$ns: '.page-team';

#{$ns} {
  .copy-block {
    overflow: hidden;
    padding: 100px 0 100px;
    background: #dfe5ea;
    @include medium-up {
      padding: 180px 0;
    }
    position: relative;

    @include medium-down {
      @include subheadline1;
    }

    .grid-container {
      display: flex;

      @include medium-down {
        display: block;
      }
    }

    &__copy {
      @include subheadline1;

      @include medium-up {
        @include headline3;
      }
    }

    &__triangle {
      @include medium-down {
        display: block;
        top: -296px;
        right: 0px;
        position: absolute;
        // transform: scale(0.4);
        zoom: 40%;
      }
    }

    &:before {
      // content: '';
      // background: url(https://prismic-io.s3.amazonaws.com/alliance-consumer-growth%2F1b1f0284-0695-40af-b4eb-5557bc56ff3e_small-dot-triangle.png);
      // position: absolute;
      // width: 140px;
      // height: 245px;
      // right: 0;
      // top: -145px;

      @include medium-up {
        background: url(https://prismic-io.s3.amazonaws.com/alliance-consumer-growth%2F63fb4f25-0c56-4a26-b862-f521c61e8002_half-triangle.png);
        width: 425px;
        height: 595px;
        top: -300px;
      }
    }
  }

  .hero {
    &--white {
      // position: static;
    }

    &__headline {
      @include headline1;

      @include small-down {
        @include headline1-mobile;
      }
    }
    
    @include medium-down {
      // min-height: 823px;
    }

    @include small-down {
      // min-height: 786px;
      min-height: auto;
      padding-bottom: 40px;
      position: relative;

      .grid-container {
        padding-bottom: 220px;
      }
    }

    @include large-up {
      min-height: 600px;
    }
  }

  [class^="page-team__triangle"] {
    position: absolute;

    @include medium-down {
      // transform: scale(0.5);
      zoom: 40%;
    }
  }

  &__triangle {
    top: 360%;
    @include medium-down {
      // top: 282.5%;
      // left: -11%;
      display: none;
    }

    &2 {
      top: 464px;
      z-index: 1;
      right: 0;

      @include medium-down {          
        top: 62%;
        right: -109px;
      }
    }
  }
}
