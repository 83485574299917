$ns: '.team-list';

#{$ns} {
  padding: 100px 0;

  @include small-down {
    padding: 100px 0 18px 0;
  }

  @include medium-up {
    padding: 180px 0;
  }
  &__headline {
    @include headline3;
    margin: 0 0 60px;
  }

  &__title {
    @include subheadline1;
    color: $black;
    margin-bottom: 10px;
    padding-top: 20px;

    @include link-hover;

    @include small-down {
      @include subheadline3;
    }
  }

  &__position {
    @include bodyCopyMed;
    padding-bottom: 20px;

    @include small-down {
      @include bodyCopySmall;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;

    &--image-wrapper {
      overflow: hidden;

      img {
        @include animation-img;
      }
    }
  }

  &__list--item {
    width: 280px;
    height: 348px;
    padding-bottom: 162px;

    &:not(:nth-child(3n)) {
      @include small-up {
        padding-right: 120px;
      }

      @media screen and (max-width: 889px) {
        padding-right: 1%;
      }

    }      

    @media screen and (max-width: 1200px) {
      &:not(:nth-child(2n)) {
        @include small-up {
          padding-right: 120px;
        }

        
        @include small-down {
          padding-right: 25%;
        }

        @media screen and (max-width: 480px) {
          padding-right: 4%;
        }
      }
    }

    @include small-down {
      width: 160px;
      height: 267px;
      padding-bottom: 85px;
    }

    img {
      width: 280px;
      height: 348px;

      @include medium-down {
        &:hover {
          transform: none;
        }
      }

      @include small-down {
        width: 160px;
        height: 200px;
      }
    }
  }
}
