$ns: '.disclosure';

#{$ns} {
  // padding-top: 17%;
  padding-bottom: 154px;
  padding-top: 260px;
  max-width: 1078px;
  margin: 0 auto;

  @include small-down {
    padding-top: 203px;
    padding-bottom: 100px;
  }

  @include padding-side;

  &__title {
    padding-top: 15px;
    @include subheadline1;
  }

  &__paragraph {
    text-transform: uppercase;
    @include bodyCopyMed;

    &:not(:last-of-type) {
      padding-bottom: 12px;
    }
  }

  &__sectionContainer {
    display: grid;
    grid-template-columns: 398px 580px;

    @include small-down {
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }

    &:first-of-type {
      padding-bottom: 60px;
    }
  }
}