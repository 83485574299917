$ns: '.home-slideshow';

#{$ns} {
  clip-path: polygon(0 0, 0 0, 100% 50%, 0 100%);
  // background-image: url(https://prismic-io.s3.amazonaws.com/alliance-consumer-growth%2F179c5c60-7e5f-455e-a8ac-ff8ff4b530db_slider.png);
  height: 861px;
  width: 627px;
  position: absolute;
  top: 32px;
  left: 0;
  z-index: 1;

  @media screen and (max-width: 1240px) {
    top: -90px;
    left: -126px;
    transform: scale(0.6);
  }
}

.home-slider {
  padding-top: 33px;
  .slick-slide {
    img {
      display: block;
      clip-path: polygon(0 0, 0 0, 100% 50%, 0 100%);
      // clip-path: polygon(0% 0%, 13% 0%, 46% 50%, 0% 113%);
      // width: 625px;
      // height: 858px;
      width: 627px;
      height: 859px;


      @media screen and (min-width: 2013px) {
        width: 1054px;
        height: 1518px;
      }
      object-fit: cover;

      -webkit-backface-visibility: hidden;
    }
  }

  @media screen and (min-width: 2013px) {
    padding-top: 0;
  }

  &__image {
    // &-overlay {
    //   clip-path: polygon(0 0, 0 0, 100% 50%, 0 100%);
    //   background: $lightBlue;
    //   z-index: 50000;
    //   position: absolute;
    //   width: 625px;
    //   height: 858px;
    // }

    &-container {
      clip-path: polygon(0 0, 0 0, 100% 50%, 0 100%);
      width: 625px !important;
      height: 858px;

      @media screen and (min-width: 2013px) {
        // width: 1050px !important;
        // height: 1516px;
        width: 1036px !important;
        height: 1497px;
        margin-top: -360px;
      }

      background-color: #00A0E3;
      -webkit-backface-visibility: hidden;
    }
  }
}

.hero--home {
  margin-top: -900px;
}

@media screen and (max-width: 1220px) {
  .home-slider {
    padding-top: 120px;
    .slick-slide {
      img {
        width: 275px;
        height: 378px;
      }
    }

    &__image {
      &-container {
        width: 274px !important;
        height: 377px;
      }
    }
  }
}

@media screen and (min-width: 1440px) {
  .home-slider {
    max-width: 1440px;
    margin: 0 auto;
  }
}