$ns: '.footer';

#{$ns} {
  background-color: $black;
  height: 342px;
  // overflow: hidden;
  // @include padding-side;

  .grid-container {
    width: 1180px;
  }

  &__container {
    // margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    // @include padding-side;
    padding-top: 70px;
    padding-bottom: 70px;
  }

  &__menu {
    width: 586px;

    &--desktop {
      display: flex;
      justify-content: space-between;
      padding-left: 0;
    }

    &--item {
      @include subheadline1;

      a {
        color: white;
      }

      &.active {
        &:hover {
          color: blue;
        }
        @include animation-border-bottom($lightBlue);
      }
    }
  }

  &__link {
    @include animation-border-bottom(transparent);
  }

  &__newsletter {

    position: relative;
    @include small-down {
      width: 100%;
    }

    input {
      // width: 100%;
      width: 310px;
      padding-right: 70px;
      padding-bottom: 13px;
      color: white;

      @include small-down {
        // width: 268px;
        // width: 177px;
        width: 100%;
      }
    }

    label {
      @include bodyCopyMed;
      color: white;
      padding-bottom: 20px;
    }

    input[type='text'],
    select {
      background: transparent;
      border: none;
      border-bottom: 1px white solid;
      @include subheadline1;
    }

    input:focus {
      outline: none;
      border-bottom: $lightBlue 1px solid;

      transition: border-bottom 0.3s ease-in-out;
      // .footer__newsletter--underline:after {
      //   transform: scaleX(1);
      // }
    }

    input:-webkit-autofill {
      -webkit-box-shadow:0 0 0 50px $black inset; /* Change the color to your own background color */
      -webkit-text-fill-color: white;
    }
    
    input:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0 50px $black inset;
        -webkit-text-fill-color: white;
    } 

    //Success/Error messages
    .error:focus {
      border-bottom: $color-error 1px solid;
    }

    &--success {
      position: absolute;
      color: $lightBlue;
      top: 110px;
    }

    &--error {
      position: absolute;
      color: $color-error;
      top: 110px;
    }

    &--button {
      background: transparent;
      border: none;
      // border-bottom: 1px white solid;
      color: white;
      @include body-fontReg;
      font-size: 15px;
      letter-spacing: 0;
      line-height: 26px;
      padding: 0;
      margin: 0;
      margin-left: -70px;

      &:focus {
        outline: none;
      }

      &:hover {
        cursor: pointer;
        color: $lightBlue;
        transition: color 0.3s ease-in-out;
      }
    }

    &--form {
      display: flex;
      padding-top: 33px;
    }
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    // @include padding-side;
    @include disclaimer(0.5);

    padding-top: 25px;
    max-width: 1180px;
    margin: 0 auto;
    
    &--link {
      @include disclaimer(1);

      &:not(:last-child) {
        padding-right: 12px;
      }
    }

    &--copyright {
    }

    &--disclosure {
      color: inherit;

      @include animation-border-bottom(transparent); 
    }

    &--social {
      display: flex;

      &-title {
        @include animation-border-bottom(transparent);
      }
    }
  }

  // Media Queries
  @media screen and (max-width: 1080px) {
    &__menu {
      width: auto;

      &--desktop {
        justify-content: unset;
        align-content: space-between;
        flex-direction: column;
        padding: 0 0 50px 0;
      }

      &--item {
        padding-bottom: 15px;
      }
    }

    &__container {
      padding-bottom: 0px;
    }

    &__bottom {
      padding-top: 0;
      padding-bottom: 20px;
    }

    height: auto;
  }

  @media screen and (max-width: 830px) {
    &__container {
      flex-direction: column;
      align-items: baseline;
    }

    &__bottom {
      padding-top: 80px;
      display: flex;
      flex-direction: column-reverse;
      justify-content: flex-end;
      align-items: flex-start;
    }
  }
}